<template>
  <div class="row pr cinzento m-0" style="min-height: 100vh;">
    <modal name="opcoesEventos" height="auto" :scrollable="scrollable" :adaptive="true" @re-open="scrollable = true" @before-close="scrollable = false" style="max-height: 100vh;overflow-y: auto;">
      <div class="panel m-0">
        <div class="panel-heading pr" style="background:#65A9E8;color:#fff;">
          <button @click="$modal.hide('opcoesEventos');" class="float-right btn btn-pure btn-md"><i class="icon wb-close"></i></button>
          <div class="clearfix"></div>
          <h3 class="panel-title text-uppercase texto-branco text-center">Adicionar Bilhete - {{modalShow.title}}</h3>
        </div>
        <div class="panel-body row">

          <h3 class="panel-title p-10">Pesquisar Contacto</h3>
          <div class="col-lg-12" v-if="!novoContactoModal">
            <div :class="{'form-group': 1, 'mb-0': (contactoPesquisar.length || contactosPesquisar.length) && !form.client_id}">
              <div class="input-group" id="fsdfdsfs343424">
                <span class="input-group-addon">Pesquisa<span class="verm">*</span></span>

                <input style="background:#f2a654;color:#fff;" ref="contactos" type="text" class="form-control foco" v-model="contactoPesquisar" placeholder="Pesquise por nome/telefone/email" id="contactos" @keydown.up.prevent.stop @keyup.prevent.stop="pesqClienteAntes($event)" autocomplete="off" :disabled="form.client_id > 0">
                <!--<a v-if="!form.client_id" :title="l('novo_Contato')" @click="addContacto()" href="javascript:void(0)" class="input-group-addon"><i class="icon wb-plus"></i></a>-->
                <a v-if="form.client_id"  @click="delContacto()" href="javascript:void(0)" class="input-group-addon"><i class="icon wb-trash"></i></a>
              </div>
            </div>
          </div>
          <div class="clearfix"></div>

          <div class="table-responsive col-lg-12"  v-if="(contactoPesquisar.length || contactosPesquisar.length) && !novoContactoModal">
            <table class="table table-hover width-full table-condensed table-striped">
              <thead>
                <tr>
                  <th class="FundoHeaderPesquisar">{{l('nome')}}</th>
                  <th class="FundoHeaderPesquisar width-100">{{l('telefone')}}</th>
                </tr>
              </thead>
              <tbody id="listaClientes">
                <tr v-for="(dt,index) in contactosPesquisar" :key="index" @click="selContato1(dt)" :class="{'selCliente': !index}" @mouseenter="mouseEnterFN(index)">
                  <td class="text-left mao Verde1 texto-branco" v-html="dt.name.toString().replace(RegExp(contactoPesquisar, 'gi'), '<b>$&</b>')"></td>
                  <td v-if="dt.phone" class="text-left mao Verde1 texto-branco" v-html="dt.phone.toString().replace(RegExp(contactoPesquisar, 'gi'), '<b>$&</b>')"></td>
                  <td class="Verde1 texto-branco" v-else></td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="form-group col-lg-12" v-if="!form.client_id && (contactoPesquisar == '' || (contactoPesquisar.length >= 9 && contactosPesquisar.length === 0))">
            <button class="btn btn-default btn-block" v-if="!novoContactoModal" @click="novoContactoModal = 1; form.phone = contactoPesquisar;">Adicionar Novo Contacto</button>
            <button class="btn btn-warning btn-block" v-else @click="novoContactoModal = 0">Pesquisar Contacto</button>
          </div>
          <template v-if="novoContactoModal || form.client_id">
            <h3 class="panel-title p-10" v-if="!form.client_id">Novo Contacto</h3>

            <div class="form-group col-lg-12">
              <div class="input-group">
                <span class="input-group-addon">Nome<span class="verm">*</span></span>
                <input type="text" class="form-control" v-model="form.name" name="name" required />
              </div>
            </div>
            <div class="form-group col-lg-12">
              <div class="input-group">
                <span class="input-group-addon">Contribuinte</span>
                <input type="text" class="form-control" v-model="form.vat" name="nif" />
              </div>
            </div>
            <div class="form-group col-lg-12">
              <div class="input-group">
                <span class="input-group-addon">{{l('email')}} <span class="verm">**</span></span>
                <input type="email" class="form-control" v-model="form.email" name="email" :required="!form.email" />
              </div>
            </div>
            <div class="form-group col-lg-12">
              <div class="input-group">
                <span class="input-group-addon">{{l('telefone')}} <span class="verm">**</span></span>
                <input type="text" class="form-control" v-model="form.phone" name="phone" :required="!form.phone" maxlength="25" />
              </div>
            </div>
            <div class="form-group col-lg-12" data-plugin="formMaterial">
              <div class="input-group">
                <span class="input-group-addon">Código Postal</span>
                <input maxlength="8" type="text" v-mask="'####-###'" class="foco form-control" placeholder="xxxx-xxx" v-model="form.zip_code" @keyup="pesqCodigoPostal" />
              </div>
            </div>
            <div class="form-group col-lg-12" data-plugin="formMaterial">
              <div class="input-group">
                <span class="input-group-addon">Freguesia</span>
                <input type="text" class="foco form-control" v-model="form.freguesia" disabled />
              </div>
            </div>

            <div class="form-group col-lg-12" data-plugin="formMaterial">
              <div class="input-group">
                <span class="input-group-addon">Obs. Contacto</span>
                <textarea row="3" type="text" class="foco form-control" placeholder="Observações Contacto" v-model="form.observation1" @keypress="update = 1"></textarea>
              </div>
            </div>

            <div class="form-group m-0 col-lg-12" v-if="!form.client_id">
              <div class="checkbox-custom m-0 checkbox-primary float-left" @keyup.enter.prevent="FOCO">
                <input type="checkbox" id="block_notifications" v-model="form.block_notifications" :value="1">
                <label for="block_notifications">{{$t('contactoscriar.bloquear_notificacoes')}}</label>
              </div>
            </div>
          </template>
          <hr />
          <div class="col-lg-12">
            <h3 class="panel-title p-10">Bilhete(s)</h3>
          </div>

          <div class="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12">
            <div class="input-group">
              <span class="input-group-addon">crianças</span>
              <input type="text" class="form-control" v-model="form.crianca" @keyup="form.total = form.crianca.split(',').length * modalShow.price1 - (form.desc > 0 ? (form.crianca.split(',').length * modalShow.price1 * form.desc / 100) : 0)" />
            </div>
          </div>
          <div class="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12">
            <div class="input-group">
              <span class="input-group-addon">Pagamento</span>
              <select class="form-control" v-model="form.pagamento" @change="form.total = form.pagamento === 'Convite' ? 0 : form.crianca.split(',').length * modalShow.price1 - (form.desc > 0 ? (form.crianca.split(',').length * modalShow.price1 * form.desc / 100) : 0)">
                <option value=""></option>
                <option value="Numerário">Numerário</option>
                <option value="Transferência">Transferência</option>
                <option value="Mbway">Mbway</option>
                <option value="Convite">Convite</option>
              </select>
            </div>
          </div>
          <div class="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12" @keyup="form.total = form.crianca.split(',').length * modalShow.price1 - (form.desc > 0 ? (form.crianca.split(',').length * modalShow.price1 * form.desc / 100) : 0)">
            <div class="input-group">
              <span class="input-group-addon">Desconto (%)</span>
              <input type="text" class="form-control" v-model="form.desc" />
            </div>
          </div>
          <div class="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12">
            <div class="input-group">
              <span class="input-group-addon">Total (€)</span>
              <input type="text" class="form-control" v-model="form.total" disabled />
            </div>
          </div>
          <div class="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12">
            <div class="checkbox-custom m-0 checkbox-primary float-left ml-10">
              <input type="checkbox" id="pp" v-model="form.pp">
              <label for="pp">PP</label>
            </div>
          </div>
          <div class="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12">
            <div class="input-group">
              <span class="input-group-addon">Vendido em:</span>
              <select class="form-control" style="margin-left:20px;" v-model="form.pago" v-if="$store.state.user.email === 'fserra18@gmail.com' || $store.state.user.email === 'vania_ss@hotmail.com' || $store.state.user.email === 'landiastaff@balizaslandia.pt'">
                <option value="smf">SMF</option>
                <option value="oaz">OAZ</option>
              </select>
            </div>
          </div>
          <div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="input-group">
              <span class="input-group-addon">Observações</span>
              <textarea class="form-control" v-model="form.obs"></textarea>
            </div>
          </div>
          <div class="clearfix"></div>
          <a @click="atualizarBilhetes()" class="btn btn-block btn-primary" href="javascript:void(0)">Guardar</a>
          <a @click="atualizarBilhetes(1)" class="btn btn-block btn-primary" href="javascript:void(0)">Guardar / Email</a>
          <a @click="atualizarBilhetes(2)" class="btn btn-block btn-primary" href="javascript:void(0)">Guardar / Email / Faturar</a>
          <a @click="$modal.hide('opcoesEventos');" class="btn btn btn-block btn-default" href="javascript:void(0)">Cancelar</a>
        </div>
      </div>
    </modal>

    <div class="text-center balizas-calendario cinzento">
      <a @click="SET_BALIZAS('POS');$router.push('/');" class="mao">
        <img :src="$store.state.balizaslandia === 'smf' ? $store.state.logoBalizaslandia1 : $store.state.logoBalizaslandia2" style="width:95%; max-width: 230px;" /></a>
      <div>
        <div class="p-10"><button class="btn btn-default" @click="onDayClick">Hoje</button></div>
        <v-calendar ref="calendar" :attributes="attributes" @dayclick="onDayClick"></v-calendar>
      </div>
    </div>
    <div class="balizas-tabela cinzento1">
        <router-link class="btn btn-primary mt-10 ml-20 pl-40 pr-40 cinzento" to="/balizaslandia"><b>MARCAÇÃO DE FESTAS</b></router-link>
        <router-link class="btn btn-primary mt-10 ml-20 pl-40 pr-40 cinzento" to="/balizaslandiaLivre"><b>ENTRADAS LIVRES</b></router-link>
        <h3 class="ml-20 mt-10">Eventos
          <button @click="$router.push('/balizaslandiacriarEvento')" class="btn btn-default" style="background:#fff;border: solid 1px #000; color:#000;">Inserir Evento</button>
        </h3>

        <div class="cinzento1">
          <table id="tabelaFixa" class="table balizas">
            <thead>
              <tr>
                <!--<th style="top:0px;width:56px;" :class="{'meio text-center': 1, azul: $store.state.balizaslandia === 'smf', amarelo: $store.state.balizaslandia === 'oaz'}">Férias</th>-->
                <th style="top:0px;width:200px;" :class="{'meio text-center': 1, azul: $store.state.balizaslandia === 'smf', amarelo: $store.state.balizaslandia === 'oaz'}">Evento</th>
                <th style="top:0px;width:100px;" :class="{'meio text-center': 1, azul: $store.state.balizaslandia === 'smf', amarelo: $store.state.balizaslandia === 'oaz'}">Data</th>
                <th style="top:0px;width:100px;" :class="{'meio text-center': 1, azul: $store.state.balizaslandia === 'smf', amarelo: $store.state.balizaslandia === 'oaz'}">Início</th>
                <th style="top:0px;width:100px;" :class="{'meio text-center': 1, azul: $store.state.balizaslandia === 'smf', amarelo: $store.state.balizaslandia === 'oaz'}">Fim</th>
                <th style="top:0px;width:60px;" :class="{'meio text-center': 1, azul: $store.state.balizaslandia === 'smf', amarelo: $store.state.balizaslandia === 'oaz'}">Entrada (€)</th>
                <th style="top:0px;width:60px;" :class="{'meio text-center': 1, azul: $store.state.balizaslandia === 'smf', amarelo: $store.state.balizaslandia === 'oaz'}">Vendidos</th>
                <!--<th style="top:0px;width:60px;" :class="{'meio text-center': 1, azul: $store.state.balizaslandia === 'smf', amarelo: $store.state.balizaslandia === 'oaz'}">Total(€)</th>-->
                 <th style="top:0px;width:200px;" :class="{'meio text-center': 1, azul: $store.state.balizaslandia === 'smf', amarelo: $store.state.balizaslandia === 'oaz'}">Observações</th>
                 <th style="top:0px;width:20px;" class="cinzento"></th>
              </tr>
            </thead>
            <tbody style="color:#000;">
              <tr v-for="(dt, index) in res" :key="index" :class="{'mao': 1, 'cinzento1': meta(dt, 'saiu') }" @click="$router.push('/balizaslandiacriarEvento/' + dt.id)">
                <!--<td>
                  <svg v-if="meta(dt, 'Fotografia')" style="width:25px;" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 50 50" xml:space="preserve"><circle style="fill:#25AE88;" cx="25" cy="25" r="25"></circle><polyline style="fill:none;stroke:#FFFFFF;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" points="  38,15 22,33 12,25 "></polyline><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg>
                </td>-->
                <td class="meio">{{dt.title}}</td>
                <td class="meio">{{dt.event_at.split(' ')[0]}}</td>
                <td class="meio">{{dt.event_at.substring(11, 16)}}</td>
                <td class="meio">{{meta(dt, 'tarde')}}</td>
                <td class="meio">{{dt.price1}}</td>
                <td class="meio">{{totalBilhetes(dt)}}</td>
                <!--<td class="meio">{{JSON.parse(meta(dt, 'manha') || []).filter(a => a.nome).length * dt.price}} / {{dt.price * meta(dt, 'Sitio')}}</td>-->
                <td class="meio">{{dt.observation}}</td>
                <td @click.stop="$modal.show('opcoesEventos'); modalShow = dt; contactoPesquisar = ''; contactosPesquisar: []; form = { pagamento: '', total: '', crianca: '', obs: '', pago: 'smf' }"><a class="btn btn-success">Ad. Bilhete</a></td>
              </tr>
            </tbody>
          </table>
        </div>
    </div>
  </div>
</template>

<script>
import { searchEvent } from '@/resources/fn'
import { mapMutations } from 'vuex'
import { commonFunctionsMixin } from '../filters/funcoes'

import { patchEventClient, newsletter, createEventClient, createClient } from '@/resources/fn'

export default {
  name: 'pageBalizadslandiaEventos',
  mixins: [commonFunctionsMixin],
  data () {
    return {
      res: [],
      year: '',
      month: '',
      day: '',
      attributes: [{
        dates: new Date(),
        key: 'today',
        highlight: true
      }],
      ano: new Date().getFullYear() + 1,
      anos: [],
      scrollable: false,
      holidays: [],
      dataMin: '2017-01-01',
      modalShow: {},
      form: {},

      novoContactoModal: 0,
      contactoPesquisar: '',
      contactosPesquisar: []
    }
  },
  mounted () {
    this.SET_TITLE('')
    this.SET_LOAD(true)
    let a = new Date()
    let xxx = decodeURIComponent(window.location.search).substring(3)
    if (xxx) {
      a = new Date(xxx.replace(/-/g, '/'))
      this.attributes[0].dates = a
      this.$refs.calendar.move(a)
    }
    this.month = ('0' + (1 + a.getMonth())).slice(-2)
    this.year = a.getFullYear()
    this.day = ('0' + a.getDate()).slice(-2)
    a = 2017
    while (a <= this.year + 1) {
      this.anos.push(a++)
    }
    this.init()
  },
  methods: {
    ...mapMutations([
      'SET_LOAD',
      'SET_TOAST',
      'SET_TITLE',
      'SET_BALIZAS',
      'SET_IMPRIMIR'
    ]),
    totalBilhete () {
      let t = 0
      this.form.forEach(a => {
        t += a.crianca ? a.crianca.split(',').length : 0
      })
      console.log(t)
      this.form.total = t
    },
    totalBilhetes (dt) {
      let  t = 0
      const x = JSON.parse(this.meta(dt, 'manha'))
      if (Array.isArray(x)) {
        x.forEach(a => {
          if (a.crianca) {
            t += a.crianca.split(',').length
          }
        })
      }
      return t
    },
    l (a) {
      return this.$t('home.' + a)
    },
    init () {
      this.formUrl('', 1)
      this.feriadosFN(this.year)
    },
    formUrl () {
      this.res = []
      this.SET_LOAD(true)
      let cal = []
      switch (this.$store.state.balizaslandia) {
        case 'smf':
          cal = [129]
          break
        case 'oaz':
          cal = [130]
          break
      }
      searchEvent(this.axios, cal, this.year, '', '', '', '', '', '').then((res) => {
        this.res = res.data.map(a => {
          a.price1 = parseFloat(a.price || 0) + parseFloat(this.meta(a, 'caucao_paga') || 0)
          return a
        })
        
        this.SET_LOAD()
      }, (er) => {
        this.SET_TOAST({ msg: er.data, type: 'danger' })
      })
    },
    onDayClick (dia) {
      if (!dia.id) {
        let a = new Date()
        this.month = ('0' + (1 + a.getMonth())).slice(-2)
        this.year = a.getFullYear()
        this.day = ('0' + a.getDate()).slice(-2)
        dia = { id: this.year + '-' + this.month + '-' + this.day }
      }
      this.attributes[0].dates = new Date(dia.id.replace(/-/g, '/'))
      this.$refs.calendar.move(dia.id)
      this.month = dia.id.split('-')[1]
      this.year = dia.id.split('-')[0]
      this.day = dia.id.split('-')[2]
      this.feriadosFN(this.year)
      // this.formUrl()
    },
    horaFN () {
      let a = new Date()
      // a.setMinutes(a.getMinutes() + 5)
      return ' ' + (a.getHours() < 10 ? '0' + a.getHours() : a.getHours()) + ':' + (a.getMinutes() < 10 ? '0' + a.getMinutes() : a.getMinutes()) + ':00'
    },
    saidaFN (horas) {
      let a = new Date()
      return a > new Date(a.getFullYear() + '-' + (1 + a.getMonth()) + '-' + a.getDate() + ' ' + horas)
    },
    atualizarBilhetes (faturar) {
      const dta = {
        name: this.form.name,
        email: this.form.email,
        phone: this.form.tel,
        vat: this.form.vat,
        block_notifications: this.form.block_notifications,
        distrito: this.form.distrito,
        concelho: this.form.concelho,
        freguesia: this.form.freguesia,
        zip_code: this.form.zip_code,
        observation: this.form.observation1
      }
      if (this.novoContactoModal) {
         createClient(this.axios, dta).then((res) => {
          this.form.client_id = res.data.id
          this.atualizarBilhetes1(faturar)
         }, (er) => {
          this.SET_TOAST({ msg: this.$t(er.response.data.error), type: 'error' })
        })
      } else {
        this.atualizarBilhetes1(faturar)
      }
    },
    atualizarBilhetes1 (faturar) {
      const dta = {...this.modalShow}
      let bilhetes = []
      if (this.meta(this.modalShow, 'manha')) {
        bilhetes = JSON.parse([this.meta(this.modalShow, 'manha')])
      }
      bilhetes.push({
        crianca: this.form.crianca,
        obs: this.form.obs,
        total: this.form.total,
        pagamento: this.form.pagamento,
        desc: this.form.desc || 0,
        nome: this.form.name,
        email: this.form.email,
        tel: this.form.phone,
        pp: faturar == 2 || this.form.pp,
        data: this.getData() + ' ' + this.gethora()
      })
      dta.event_at = dta.event_at.substring(0, 16)
      dta.meta = {
        manha: JSON.stringify(bilhetes),
        tarde: this.meta(this.modalShow, 'tarde'),
        utilizador: this.meta(this.modalShow, 'utilizador'),
        saiu: this.meta(this.modalShow, 'saiu'),
        Fotografia: this.meta(this.modalShow, 'Fotografia'), // campo de férias
        caucao_paga: this.meta(this.modalShow, 'caucao_paga') // preço da diversao
      }
      patchEventClient(this.axios, dta).then((res) => {
        this.form.title = res.data.title
        this.SET_LOAD()
        this.$modal.hide('opcoesEventos')

        if (this.form.total > 0) {
          let cal = []
          switch (this.$store.state.balizaslandia) {
            case 'smf':
              cal = [133]
              break
            case 'oaz':
              cal = [134]
              break
          }
          if (this.$store.state.user.email === 'fserra18@gmail.com' || this.$store.state.user.email === 'vania_ss@hotmail.com' || this.$store.state.user.email === 'landiastaff@balizaslandia.pt') {
            if (this.form.pago === 'smf') {
              cal = [133]
            } else {
              cal = [134]
            }
          }
          let dta = {
            calendar_id: cal[0],
            color: '#ff0000',
            event_at: (this.getData() + ' ' + this.gethora()).substring(0, 16),
            duration_minutes: 60,
            title: 'Contas Fecho',
            client_id: 10471,
            repeat: 'no',
            // observation: this.form.obs,
            meta: {
              'Total1': this.form.total,
              'Total2': this.form.pagamento,
              'PP': this.form.pp
            }
          }
          createEventClient(this.axios, dta).then(() => {
          }, () => {})

          this.formUrl()
        }
        this.SET_TOAST({ msg: 'Bilhetes adicionados com sucesso.' })
        if (this.form.email && faturar) {
          this.enviarMail()
        }
        if (faturar === 2 && this.form.pagamento !== 'Convite') {
          this.faturarFN()
        }
        
        /*
        patchClient(this.axios, { id: res.data.eventable.id, name: this.form.name, phone: this.form.phone, email: this.form.email, zip_code: this.form.zip_code, freguesia: this.form.freguesia, observation: this.form.observation1 }).then(() => {
          this.SET_LOAD()
        }, (er) => {
          if (typeof er.response.data.errors === 'object' && er.response.data.errors !== null && !Array.isArray(er.response.data.errors)) {
            for (const obj in er.response.data.errors) {
              this.SET_TOAST({ msg: this.$t(er.response.data.errors[obj]), type: 'error' })
              return
            }
          } else {
            this.SET_TOAST({ msg: this.$t(er.response.data.error), type: 'error' })
          }
        })
        */
      }, (er) => {
        this.SET_TOAST({ msg: this.$t(er.response.data.error), type: 'error' })
      })
    },
    faturarFN () {
      // ffaturar
      let criancas = this.form.crianca.split(',').length
      let prods = []
      if (this.meta(this.modalShow, 'Fotografia')) {
        // campo de faerias
        prods.push({ item_id: 170675, nome: 'Campo de Férias', quantidade: criancas, preco_unitario: this.form.price / 1.23, imposto_id: 379, desconto_1: this.form.desc > 0 ? this.form.desc : 0, desconto_2: 0, desconto_3: 0 })
      } else {
        if (this.meta(this.modalShow, 'caucao_paga') > 0) {
          prods.push({ item_id: 193842, nome: 'Kids Night Party - Diversão', quantidade: criancas, preco_unitario: this.meta(this.modalShow, 'caucao_paga') / 1.23, imposto_id: 379, desconto_1: this.form.desc > 0 ? this.form.desc : 0, desconto_2: 0, desconto_3: 0 })
        }
        if (this.modalShow.price > 0) {
          prods.push({ item_id: 193842, nome: 'Kids Night Party - Refeição', quantidade: criancas, preco_unitario: this.modalShow.price / 1.13, imposto_id: 380, desconto_1: this.form.desc > 0 ? this.form.desc : 0, desconto_2: 0, desconto_3: 0 })
        }
      }

      let cc = {}
      if (this.form.vat > 0) {
        cc = {
          nif: this.form.vat,
          nome: this.form.name,
          pais: 'PT'
        }
      } else {
        cc = {
          nif: 999999990,
          nome: 'Consumidor Final',
          pais: 'PT'
        }
      }
      let local = this.$store.state.balizaslandia
      if (this.$store.state.user.email === 'fserra18@gmail.com' || this.$store.state.user.email === 'vania_ss@hotmail.com' || this.$store.state.user.email === 'landiastaff@balizaslandia.pt') {
        if (this.form.pago === 'smf') {
          local = 'smf'
        } else {
          local = 'oaz'
        }
      }
      this.axios.post('https://app.bill.pt/api/1.0/documentos', {
        api_token: 'zkZtp5LqaoEYHj0HcrKqYlQZopcBrRqHUL5YIxzf05zCLLsIPzWGTGgabmNUdMnXg7CDpJ14HwyFv0QfCVmfpyXvJ2cojNHMNX3KKlu6wtgA4mNyHNAkYcOhrprRqizB',
        tipo_documento_id: 3,
        tipificacao: 'FR',
        contato: cc,
        produtos: prods,
        loja_id: local === 'oaz' ? 1410 : 1409,
        serie_id: local === 'oaz' ? 2332 : 2331,
        metodo_pagamento_id: (this.form.pagamento === 'Mbway' || this.form.pagamento === 'Transferência') ? 506 : 515,
                          // metodo_expedicao_id: ,
        data: this.getData() + ' ' + this.gethora(0, 1),
        prazo_vencimento: this.getData() + ' ' + this.gethora(0, 1),
        terminado: 1,
        pos_id: local === 'oaz' ? 251 : 252
      }).then((res) => {
        this.SET_LOAD(true)
        this.axios.get('https://app.bill.pt/documentos/download/' + res.data.id + '/' + res.data.token_download + '?tamanho=talao').then((dta) => {
          let _id = 'id_' + new Date().getTime()
          let myframe = document.createElement('IFRAME')
          myframe.id = _id
          myframe.style = 'visibility:hidden;position:absolute;top:-10000px;width:1px;height:1px;'
          document.body.appendChild(myframe)
          myframe.contentWindow.document.open()
          myframe.contentWindow.document.write(dta.data)
          myframe.contentWindow.document.close()
          this.loadIframe(document.getElementById(_id))
        }, () => {
          this.SET_LOAD()
        })
      }, () => {
        this.SET_TOAST({ msg: 'Não foi possível criar a fatura', type: 'error' })
      })
    },
    meta1 () {
      // para nao dar erro
    },
    enviarMail () {
      console.log(this.form)
      this.SET_LOAD(true)
      let cal1 = ''
      switch (this.$store.state.balizaslandia) {
        case 'smf':
          cal1 = 129
          break
        case 'oaz':
          cal1 = 130
          break
      }
      const dd = new Date(this.modalShow.event_at)
      let form = {
        just_with_birthday: 'some',
        target: 'some',
        alert_type: 'email',
        contacts: [this.form.client_id],
        title: 'Bilhete BalizasLandia - ' + this.form.title,
        newsletter_text: 'Olá ' + this.form.name + ',<br />Agradecemos desde já a sua compra!<br />Confirmamos a aquisição de ' + this.form.crianca.split(',').length + ' bilhete(s) para o evento ' + this.form.title + ', que acontecerá no dia ' + ('0' + dd.getDate()).slice(-2) + '-' + ('0' + (1 + dd.getMonth())).slice(-2) + '-' + dd.getFullYear() + ' das ' + ('0' + dd.getHours()).slice(-2) + ':' + ('0' + dd.getMinutes()).slice(-2) + ' às ' + this.meta(this.modalShow, 'tarde').substring(0, 5) + ', em ' + (this.$store.state.balizaslandia === 'smf' ? 'Rio Meão' : 'Oliveira de Azeméis') + '.<br /><br />Detalhes da compra:<br />• Nome das crianças: ' + this.form.crianca + '<br />• Nome do responsável: ' + this.form.name + '<br /><br />Se tiver alguma dúvida ou necessitar de mais informações, p.f. entre em contacto connosco.<br />Agradecemos mais uma vez a sua confiança.<br /><br />Até já.',
        when: '1min',
        event_at: this.getData() + ' ' + this.gethora(),
        date: this.getData(),
        time: this.gethora(1),
        exclude_after: '',
        calendar_id: cal1
      }
      newsletter(this.axios, form).then(() => {
        this.SET_TOAST({ msg: 'Email enviado com sucesso.' })
        this.SET_LOAD()
      }, () => {
        this.SET_TOAST({ msg: 'Não foi possível enviar o email.', type: 'error' })
      })
    },
    getData () {
      let a = new Date()
      let mes = a.getMonth() + 1
      return a.getFullYear() + '-' + (mes < 10 ? '0' + mes : mes) + '-' + (a.getDate() < 10 ? '0' + a.getDate() : a.getDate())
    },
    gethora (m, seg) {
      let a = new Date()
      a.setMinutes(a.getMinutes() + (m || 0))
      return (a.getHours() < 10 ? '0' + a.getHours() : a.getHours()) + ':' + (a.getMinutes() < 10 ? '0' + a.getMinutes() : a.getMinutes()) + (seg ? ':' + (a.getSeconds() < 10 ? '0' + a.getSeconds() : a.getSeconds()) : '')
    }
  }
}
</script>
