<template>
  <div class="row">
    <div class="text-center balizas-calendario">
      <a @click="sairFN()" class="mao">
        <img :src="$store.state.balizaslandia === 'smf' ? $store.state.logoBalizaslandia1 : $store.state.logoBalizaslandia2" style="width:95%; max-width: 230px;" />
      </a>
      <v-calendar ref="calendar" :attributes="attributes" @dayclick="onDayClick"></v-calendar>
    </div>
    <div class="balizas-tabela pl-5" v-if="metas[0]">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-xs-12 col-sm-6">
          <div class="panel mb-10" style="background:#ddd;">
            <div class="panel-heading pr">
              <h3 class="panel-title p-10">Data <small class="float-right" v-if="form.id">{{form.created_at.substring(0, 16)}}</small></h3>
            </div>
            <div class="panel-body p-0">
              <div class="wizard-content p-0 px-10">
                <div class="wizard-pane row" role="tabpanel">
                  <div class="form-group col-xs-12 col-sm-6 col-lg-6 col-md-6">
                    <div class="input-group">
                      <span class="input-group-addon">{{l('data')}} <span class="verm">*</span></span>
                      <input type="date" class="form-control" v-model="form.date" name="data" :disabled="form.id > 0" />
                    </div>
                  </div>
                  <div class="form-group col-xs-12 col-sm-6 col-lg-6 col-md-12">
                    <div class="input-group">
                      <span class="input-group-addon">Utilizador<span class="verm">*</span></span>
                      <input type="text" step="300" class="form-control" v-model="metas[metaKey('utilizador')].option.valor" name="utilizador" :disabled="form.id"  />
                    </div>
                  </div>
                  <div class="form-group col-xs-12 col-sm-6 col-lg-6 col-md-6">
                    <div class="input-group">
                      <span class="input-group-addon">Hora Início <span class="verm">*</span></span>
                      <input type="time" step="300" class="form-control" v-model="form.time" name="time1" :disabled="form.id" />
                    </div>
                  </div>
                  <div class="form-group col-xs-12 col-sm-6 col-lg-6 col-md-6">
                    <div class="input-group">
                      <span class="input-group-addon">Hora Fim <span class="verm">*</span></span>
                      <input type="time" step="300" class="form-control" v-model="metas[metaKey('tarde')].option.valor" name="time2" :disabled="form.id" />
                    </div>
                  </div>

                  <!--<div class="form-group col-xs-12 col-sm-6 col-lg-6 col-md-6" v-if="metas[0]">
                    <div class="input-group">
                      <span class="input-group-addon">Limite Bilhetes</span>
                      <input type="number" class="form-control" v-model="metas[metaKey('Sitio')].option.valor" @change="limiteBilhetesFN" />
                    </div>
                  </div>-->

                  <div class="form-group col-xs-12 col-sm-6 col-lg-6 col-md-6" v-if="form.id">
                    <div class="input-group">
                      <div class="checkbox-custom m-0 checkbox-primary float-left ml-10">
                        <input class="foco" type="checkbox" id="concluido123" v-model="metas[metaKey('saiu')].option.valor" @keydown.enter.prevent="FOCO" @click="update = 1">
                        <label for="concluido123">Concluído</label>
                      </div>
                    </div>
                  </div>

                  <!--<div class="form-group col-xs-12 col-sm-6 col-lg-6 col-md-6">
                    <div class="input-group">
                      <div class="checkbox-custom m-0 checkbox-primary float-left ml-10">
                        <input class="foco" type="checkbox" id="concluido123dfsgd" v-model="metas[metaKey('Fotografia')].option.valor" @keydown.enter.prevent="FOCO" @click="update = 1">
                        <label for="concluido123dfsgd">Campo de Férias</label>
                      </div>
                    </div>
                  </div>-->

                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-xs-12 col-sm-6 pb-10">
          <a @click="criarEvento()" style="color:#fff;" class="btn btn-primary float-right mr-20">Guardar</a>
          <a @click="sairFN()" style="color:#fff;" class="btn btn-warning float-right mr-20">Sair</a>
          <div class="clearfix"></div>
          <a @click="exportar()" v-if="bilhetes.length" style="color:#fff;" class="btn btn-success float-right mr-20 mt-10">Exportar</a><a v-if="form.id" @click="apagarEvento()" class="btn btn-danger float-right mr-20 mt-10" href="javascript:void(0)">Apagar Evento</a>
        </div>
      </div>

      <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12">
        <div class="panel mb-10" style="padding-bototm: 10px;">
          <div class="panel-heading pr">
            <h3 class="panel-title p-10">Evento</h3>
          </div>
          <div class="panel-body p-0">
            <div class="wizard-content p-0 px-10">
              <div class="wizard-pane row" role="tabpanel">
                <div class="form-group col-lg-6 col-md-6 col-xs-12 col-sm-6">
                  <div class="input-group">
                    <span class="input-group-addon">Título</span>
                    <input type="text" class="form-control" v-model="form.title" @keypress="update = 1" :disabled="form.id" />
                  </div>
                  <div class="input-group mt-10">
                    <span class="input-group-addon">Preço Refeição (€)</span>
                    <input type="number" class="form-control" v-model="form.price" :disabled="form.id" />
                  </div>
                  <div class="input-group mt-10">
                    <span class="input-group-addon">Preço Diversão (€)</span>
                    <input type="number" class="form-control" v-model="metas[metaKey('caucao_paga')].option.valor" :disabled="form.id" />
                  </div>
                  <!--<div class="input-group mt-10" v-if="metas[0]">
                    <span class="input-group-addon">Total (€)</span>
                    <input type="text" class="form-control" disabled v-if="metas[metaKey('Sitio')].option.valor && form.price" :value="(form.price * bilhetes.filter(a => a.nome).length) + ' / ' + (metas[metaKey('Sitio')].option.valor * form.price)" />
                  </div>-->
                </div>
                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-6 form-group">
                  <div class="input-group">
                    <span class="input-group-addon">Obervações</span>
                    <textarea rows="3" class="form-control" v-model="form.observation" @keypress="update = 1"></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-12" v-if="bilhetes[0]">
        <div class="panel mb-10">
          <div class="panel-heading pr">
            <h3 class="panel-title p-10">Bilhetes ({{total}})<!--<b>({{bilhetes.filter(a => a.nome).length}} / {{metas[metaKey('Sitio')].option.valor}})</b>--></h3>
          </div>
          <div class="panel-body p-0">
            <div class="wizard-content p-0 px-10">
              <div class="wizard-pane" role="tabpanel">
                <input type="text" class="form-control" v-model="search" placeholder="pesquisar..." />
                <table id="tabelaFixa" class="table balizas">
                  <thead>
                    <tr>
                      <th style="top:0px;width: 150px;" :class="{'meio text-center': 1, azul: $store.state.balizaslandia === 'smf', amarelo: $store.state.balizaslandia === 'oaz'}">Nome</th>
                      <th style="top:0px;width: 200px;" :class="{'meio text-center': 1, azul: $store.state.balizaslandia === 'smf', amarelo: $store.state.balizaslandia === 'oaz'}">Email</th>
                      <th style="top:0px;width: 150px;" :class="{'meio text-center': 1, azul: $store.state.balizaslandia === 'smf', amarelo: $store.state.balizaslandia === 'oaz'}">Telemóvel</th>
                      <!--<th style="top:0px;width:300px;" :class="{'meio text-center': 1, azul: $store.state.balizaslandia === 'smf', amarelo: $store.state.balizaslandia === 'oaz'}">Email<br />Contacto</th>-->
                      <th style="top:0px;width:200px;" :class="{'meio text-center': 1, azul: $store.state.balizaslandia === 'smf', amarelo: $store.state.balizaslandia === 'oaz'}">Crianças</th>
                      <th style="top:0px;" :class="{'meio text-center': 1, azul: $store.state.balizaslandia === 'smf', amarelo: $store.state.balizaslandia === 'oaz'}">Observações</th>
                      <th style="top:0px;width:100px;" :class="{'meio text-center': 1, azul: $store.state.balizaslandia === 'smf', amarelo: $store.state.balizaslandia === 'oaz'}">Total<br />Pagamento</th>
                      <th style="top:0px;width:150px;" :class="{'meio text-center': 1, azul: $store.state.balizaslandia === 'smf', amarelo: $store.state.balizaslandia === 'oaz'}">Data</th>
                      <th style="top:0px;" :class="{'meio text-center': 1, azul: $store.state.balizaslandia === 'smf', amarelo: $store.state.balizaslandia === 'oaz'}">PP</th>
                      <th style="width:30px;"></th>
                    </tr>
                  </thead>
                  <tbody style="color:#000;">
                    <tr v-for="(dt, ind) in bilhetes.filter(a => search.length === 0 || a.crianca.toString().toLowerCase().indexOf(search.toString().toLowerCase()) >= 0 || a.nome.toString().toLowerCase().indexOf(search.toString().toLowerCase()) >= 0 || a.email.toString().toLowerCase().indexOf(search.toString().toLowerCase()) >= 0 || a.tel.toString().toLowerCase().indexOf(search.toString().toLowerCase()) >= 0)" :key="ind" :class="{'mao': 1, 'cinzento1': meta(dt, 'saiu') }">
                      <td class="meio">
                        {{dt.nome}}
                      </td>
                      <td class="meio">
                        {{dt.email}}
                      </td>
                      <td class="meio">
                        {{dt.tel}}
                      </td>
                      <!--<td class="meio">
                        <input type="text" class="form-control" v-model="dt.email" placeholder="Email" />
                        <button class="btn btn-default float-right" v-if="dt.email" @click="enviarMail(dt)">Enviar Email</button>
                        <br />
                        <input type="text" class="form-control" v-model="dt.telefone" placeholder="Telefone" />
                      </td>-->
                      <td class="meio">
                        {{dt.crianca}}
                        <!--<input type="text" class="form-control" v-model="dt.crianca" placeholder="Crianças" @keyup="dt.total = (dt.crianca.split(',').length * form.price || '')" />-->
                      </td>
                      <td class="meio">
                        <textarea v-model="dt.obs" class="form-control"></textarea>
                      </td>
                      <td class="meio">
                        {{dt.total}} <span v-if="dt.desc > 0">({{dt.desc}}%)</span><br />
                        {{dt.pagamento}}
                      </td>
                      <td class="meio">
                        {{dt.data}}
                      </td>
                      <td class="meio" style="width:40px;">
                        <svg v-if="dt.pp" style="width:25px;" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 50 50" xml:space="preserve"><circle style="fill:#25AE88;" cx="25" cy="25" r="25"></circle><polyline style="fill:none;stroke:#FFFFFF;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" points="  38,15 22,33 12,25 "></polyline><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg>
                      </td>
                      <td class="text-center meio">
                        <button type="button" @click="bilhetes.splice(ind, 1)" class="btn btn-danger btn-xs mr-5"><i class="icon wb-trash"></i></button>
                      </td>
                    </tr>
                  </tbody>

                  <tfoot>
                    <tr>
                      <th colspan="5" class="text-right">Total</th>
                      <th colspan="3">{{bilhetes.map(item => item.total).reduce((a, b) => a + b, 0).toFixed(2)}} €</th>
                    </tr>
                  </tfoot>
                </table>
                <!--<button class="btn btn-primary btn-block" @click="bilhetes.push({
                  nome: '',
                  email: '',
                  telefone: '',
                  crianca: '',
                  obs: '',
                  total: '',
                  pagamento: ''
                })">Adicionar Bilhete</button>

                <div v-for="(dt, ind) in bilhetes" :key="ind" style="border-bottom: solid 1px #ccc;padding: 10px;">
                  <h4>Bilhete: <b>{{ind + 1}}</b></h4>
                  <input type="text" style="float:left;width: 250px; margin: 3px;" class="form-control" v-model="dt.nome" disabed />
                  <input type="text" style="float:left;width: 250px; margin: 3px;" class="form-control" v-model="dt.email" placeholder="Email" />
                  <input type="text" style="float:left;width: 250px; margin: 3px;" class="form-control" v-model="dt.telefone" placeholder="Telefone" />
                  <input type="text" style="float:left;width: 250px; margin: 3px;" class="form-control" v-model="dt.crianca" placeholder="Criança" />
                  <input type="text" style="float:left;width: 250px; margin: 3px;" class="form-control" v-model="dt.obs" placeholder="Observações" />
                  <button class="btn btn-default float-right" v-if="dt.email" @click="enviarMail(dt)">Enviar Email</button>
                  <div class="clearfix"></div>
                </div>
                -->
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { deleteEvent, getEvent, createEventClient, eventoMetaIns1, patchEventClient } from '@/resources/fn'
import { mapMutations } from 'vuex'
import _ from 'lodash'
import { commonFunctionsMixin } from '../filters/funcoes'

export default {
  name: 'pageBalizaslandiaCriarEvento',
  mixins: [commonFunctionsMixin],
  data () {
    return {
      form: {
        calendar_id: '',
        repeat: 'no',
        date: '',
        time: '',
        client_id: 0,
        name: '',
        phone: '',
        email: '',
        pack_id: '',
        price: '',
        event_at: '',
        observation: '',
        color: '',
        duration_minutes: 30,
        title: '',
        alerts: [],
        distrito: '',
        concelho: '',
        freguesia: '',
        zip_code: '',
        created_at: ''
      },
      feriado: 1,
      attributes: [{
        dates: new Date(),
        key: 'today',
        highlight: true
      }],
      holidays: [],
      update: 0,
      metas: [],

      bilhetes: [],

      search: ''
    }
  },
  watch: {
    form: {
      handler: function () {
        this.updade = 1
      },
      deep: true
    },
    '$route' () {
      this.init()
    }
  },
  computed: {
    total () {
      let t = 0
      this.bilhetes.forEach(a => {
        t += a.crianca ? a.crianca.split(',').length : 0
      })
      return t
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    ...mapMutations([
      'SET_LOAD',
      'SET_TOAST',
      'SET_TITLE',
      'FOCO',
      'SET_BALIZAS',
      'SET_BALIZASLANDIAS'
    ]),
    limiteBilhetesFN () {
      if (this.metas[this.metaKey('Sitio')].option.valor > 0) {
        while (this.bilhetes.length < this.metas[this.metaKey('Sitio')].option.valor) {
          this.bilhetes.push({
            nome: '',
            email: '',
            telefone: '',
            obs: '',
            crianca: ''
          })
        }
      }
    },
    onDayClick (dia) {
      this.$router.push('/balizaslandia?d=' + dia.id)
    },
    l (a) {
      return this.$t('home.' + a)
    },
    sairFN () {
      if (this.update) {
        this.$modal.show('dialog', {
          title: '<i class="danger icon wb-alert-circle mr-10"></i> Deseja sair sem gravar?',
          buttons: [
            {
              title: 'Não',
              handler: () => {
                this.$modal.hide('dialog')
              },
              class: 'btn btn-default'
            },
            {
              title: 'Sim',
              default: true,
              handler: () => {
                this.$router.push('/balizaslandiaEventos')
                this.$modal.hide('dialog')
              },
              class: 'btn btn-danger'
            }
          ]
        })
      } else {
        this.$router.push('/balizaslandiaEventos')
      }
    },

    init () {
      if (this.$store.state.calendarios1 && this.$store.state.packs1 && this.$store.state.templates1 && this.$store.state.metaeventos1 && this.$store.state.user1) {
        this.SET_TITLE('Criar Evento')
        this.metas = this.$store.state.metaeventos.map(a => {
          a.option.valor = ''
          return a
        })

        console.log(this.metas)

        this.bilhetes = []
        if (this.$route.params.id > 0) {
           this.SET_TITLE('Editar Evento')
          // editar
          getEvent(this.axios, this.$route.params.id).then((res) => {
            let dta = res.data.event_at.split(' ')
            this.form = {
              alerts: res.data.alerts,
              client_id: res.data.eventable_id,
              name: res.data.eventable.name,
              phone: res.data.eventable.phone,
              email: res.data.eventable.email,
              calendar_id: res.data.calendar_id,
              observation1: res.data.eventable.observation,
              repeat: res.data.repeat,
              date: dta[0],
              time: dta[1],
              pack_id: res.data.pack_id || '',
              price: res.data.price,
              event_at: res.data.event_at,
              observation: res.data.observation,
              color: res.data.color,
              duration_minutes: res.data.duration_minutes,
              title: res.data.title,
              id: res.data.id,
              meta: res.data.meta,
              distrito: res.data.eventable.distrito,
              concelho: res.data.eventable.concelho,
              freguesia: res.data.eventable.freguesia,
              zip_code: res.data.eventable.zip_code,
              created_at: res.data.created_at
            }
            this.feriadosFN(dta[0])
            let i = this.form.meta.length
            let x = this.metas.length
            let u
            while (--i >= 0) {
              u = x
              while (--u >= 0) {
                if (this.form.meta[i].meta_key === this.metas[u].meta_key) {
                  // this.$set(this.metas[u].option, 'valor', this.form.meta[i].meta_value)
                  this.metas[u].option.valor = this.form.meta[i].meta_value
                  break
                }
              }
            }
            if (this.metas[this.metaKey('manha')].option.valor) {
              this.bilhetes = JSON.parse(this.metas[this.metaKey('manha')].option.valor)
            } else {
              this.bilhetes = []
            }
          }, (er) => {
            this.SET_TOAST({ msg: this.$t(er.response.data.error), type: 'error' })
          })
        } else {
          let cal1 = ''
          let cont = ''
          switch (this.$store.state.balizaslandia) {
            case 'smf':
              cal1 = 129
              cont = 22578
              break
            case 'oaz':
              cal1 = 130
              cont = 22579
              break
          }
          this.form = {
            calendar_id: cal1,
            repeat: 'no',
            date: this.$store.getters.getDATA,
            time: '',
            client_id: cont,
            name: '',
            phone: '',
            observation1: '',
            email: '',
            pack_id: '',
            price: '',
            event_at: '',
            observation: '',
            color: '#009CE0',
            duration_minutes: 180,
            title: '',
            alerts: [],
            meta: {},
            distrito: '',
            concelho: '',
            freguesia: '',
            zip_code: ''
          }
          this.feriadosFN(this.form.date.substring(0, 4))
        }
        this.SET_LOAD()
      } else {
        _.delay(function (a) {
          a.init()
        }, 150, this)
      }
    },
    criarEvento () {
      this.SET_LOAD(true)
      this.form.event_at = this.form.date + ' ' + this.form.time.substring(0, 5)
      this.metas[this.metaKey('manha')].option.valor = JSON.stringify(this.bilhetes)

      if (this.form.id) {
        // meta
        this.form.meta = {
          manha: JSON.stringify(this.bilhetes),
          tarde: this.metas[this.metaKey('tarde')].option.valor,
          utilizador: this.metas[this.metaKey('utilizador')].option.valor,
          saiu: this.metas[this.metaKey('saiu')].option.valor,
          Fotografia: this.metas[this.metaKey('Fotografia')].option.valor, // campo de férias
          caucao_paga: this.metas[this.metaKey('caucao_paga')].option.valor // preço da diversao
        }
        patchEventClient(this.axios, this.form).then(() => {
          this.SET_TOAST({ msg: this.l('atualizado_sucesso') })
          this.SET_LOAD()
          this.update = 0
          this.sairFN()
        }, (er) => {
          this.SET_TOAST({ msg: this.$t(er.response.data.error), type: 'error' })
        })
      } else {
        createEventClient(this.axios, this.form).then((res) => {
          let images = new window.FormData()
          let k = this.metas.length
          let i = 0
          while (i < k) {
            images.append('meta[' + i + '][meta_key]', this.metas[i].meta_key)
            images.append('meta[' + i + '][meta_value]', this.metas[i].option.valor || '')
            images.append('meta[' + i + '][event_id]', res.data.id)
            ++i
          }
          eventoMetaIns1(this.axios, images).then(() => {
            this.SET_TOAST({ msg: this.l('criado_evento_sucesso') })
            this.SET_LOAD()
            this.update = 0
            this.sairFN()
          }, () => {
            this.SET_TOAST({ msg: this.l('criado_evento_sucesso') })
            this.SET_LOAD()
            this.update = 0
            this.sairFN()
          })
        }, (er) => {
          this.SET_TOAST({ msg: this.$t(er.response.data.error), type: 'error' })
        })
      }
    },
    apagarEvento () {
      this.$modal.show('dialog', {
        title: '<i class="danger icon wb-alert-circle mr-10"></i> Tem a certeza que pretende apagar o evento?',
        // text: '<div class="text-uppercase text-center">NÃO É POSSÍVEL APAGAR MARCAÇÕES TERMINADAS</div>',
        buttons: [
          {
            title: this.$t('cancelar'),
            handler: () => {
              this.$modal.hide('dialog')
            },
            class: 'btn btn-default'
          },
          {
            title: this.$t('confirmar'),
            default: true,
            handler: () => {
              this.SET_LOAD(true)
              deleteEvent(this.axios, this.form.id).then(() => {
                this.$modal.hide('dialog')
                this.SET_TOAST({ msg: this.$t('apagar_sucesso') })
                this.$router.push('/balizaslandiaEventos')
              }, () => {
                this.SET_TOAST({ msg: 'Não foi possível apagar o evento.', type: 'error' })
              })
            },
            class: 'btn btn-danger'
          }
        ]
      })
    },
    getData () {
      let a = new Date()
      let mes = a.getMonth() + 1
      return a.getFullYear() + '-' + (mes < 10 ? '0' + mes : mes) + '-' + (a.getDate() < 10 ? '0' + a.getDate() : a.getDate())
    },
    gethora (m, seg) {
      let a = new Date()
      a.setMinutes(a.getMinutes() + (m || 0))
      return (a.getHours() < 10 ? '0' + a.getHours() : a.getHours()) + ':' + (a.getMinutes() < 10 ? '0' + a.getMinutes() : a.getMinutes()) + (seg ? ':' + (a.getSeconds() < 10 ? '0' + a.getSeconds() : a.getSeconds()) : '')
    },

    exportar () {
      let csv = ['Nome','Email', 'Telemóvel', 'Crianças', 'Observações', 'Total', 'Desconto', 'Pagamento', 'PP'].join(';') + '\n'
      this.bilhetes.forEach(a => {
        csv += a.nome + ';' + a.email + ';' + a.tel + ';' + a.crianca + ';' + a.obs + ';' + a.total + ';' + a.desc + ';' + a.pagamento + ';' + a.pp + '\n'
      })
      csv = csv.split('/  /').join('/')
      csv = csv.split('/ ;').join(';')
      let hiddenElement = document.createElement('a')
        hiddenElement.href = 'data:text/csv; charset=utf-8,' + encodeURIComponent('\uFEFF' + csv) // 'data:text/csv;charset=utf-8,' + encodeURI(csv)
        hiddenElement.target = '_blank'
        hiddenElement.download = 'BalizasLandia-Evento - ' + this.form.title + '.csv'
        hiddenElement.click()
    }
  }
}
</script>
