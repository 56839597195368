<template>
  <div class="row pr cinzento1 m-0">
    <modal name="opcoesEventos" height="auto" :scrollable="scrollable" :adaptive="true" @before-open="scrollable = true" @before-close="scrollable = false" style="max-height: 100vh;overflow-y: auto;">
      <div class="panel m-0" v-if="eventoInfo.id">
        <div class="panel-heading pr" style="background:#65A9E8;color:#fff;">
          <button @click="$modal.hide('opcoesEventos');" class="float-right btn btn-pure btn-md"><i class="icon wb-close"></i></button>
          <div class="clearfix"></div>
          <h3 class="panel-title text-uppercase texto-branco text-center">BalizasLandia</h3>
        </div>
        <div class="panel-body pt-40 row">
          <div class="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12" v-if="!form.livre">
            <div class="input-group">
              <span class="input-group-addon">Nº Dispositivo</span>
              <select class="form-control" v-model="form.nd">
                <option value=""></option>
                <option :value="dt" v-for="(dt, index) in $store.state.metaeventos[metaKey('Nº Dispositivo')].option.valores" :key="index">{{dt}}</option>
              </select>
            </div>
          </div>
          <div class="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12" v-if="!form.livre">
            <div class="input-group">
              <span class="input-group-addon">Cor Pulseira</span>
              <select class="form-control" v-model="form.cor">
                <option value=""></option>
                <option :value="dt" v-for="(dt, index) in $store.state.metaeventos[metaKey('Cor Pulseira')].option.valores" :key="index">{{dt}}</option>
              </select>
            </div>
          </div>
          <div class="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12" v-if="!form.livre">
            <div class="input-group">
              <span class="input-group-addon">Hora do Lanche</span>
              <input type="time" step="300" class="form-control" v-model="form.hora_lanche" />
            </div>
          </div>
          <div class="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12" v-if="form.livre">
            <div class="checkbox-custom">
              <input type="checkbox" id="chamar" v-model="form.chamar">
              <label for="chamar">Chamar</label>
            </div>
          </div>
          <div class="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12" v-if="!form.livre">
            <div class="checkbox-custom">
              <input type="checkbox" id="fim" v-model="form.fim">
              <label for="fim">Concluído</label>
            </div>
          </div>

          <!--<div class="form-group col-lg-12">
            <div class="input-group">
              <span class="input-group-addon">Observações Lesões</span>
              <textarea row="3" type="text" class="form-control" v-model="form.lesoes"></textarea>
            </div>
          </div>-->
          <!--<div class="form-group col-lg-12">
            <div class="input-group">
              <button class="input-group-addon btn btn-success texto-branco" @click="imprimirFN()">Imprimir Crianças</button>
              <textarea class="form-control" row="3" readonly="readonly" v-model="form.criancas"></textarea>
            </div>
          </div>-->
          <div class="form-group col-lg-12" v-if="form.livre">
            <div class="input-group">
              <span class="input-group-addon">Observações Cacifos</span>
              <textarea disabled row="3" type="text" class="form-control" v-model="form.cacifos"></textarea>
            </div>
          </div>
          <div class="form-group col-lg-12" v-if="form.livre">
            <div class="input-group">
              <span class="input-group-addon">Observações Lanche</span>
              <textarea disabled row="3" type="text" class="form-control" v-model="form.lanche"></textarea>
            </div>
          </div>
          <div class="form-group col-lg-12" v-if="form.livre">
            <div class="input-group">
              <span class="input-group-addon">Observações</span>
              <textarea disabled row="3" type="text" class="form-control" v-model="form.observacoes"></textarea>
            </div>
          </div>

          <a @click="atualizar()" class="btn btn-block btn-primary" href="javascript:void(0)">Guardar</a>
          <a @click="$modal.hide('opcoesEventos');" class="btn btn btn-block btn-default" href="javascript:void(0)">Sair</a>
        </div>
      </div>
    </modal>
    <div class="cinzento pb-20" style="width:100%">
      <a class="icon wb-power float-right m-10" href="javascript:void(0)" @click="logout()" >
        <span class="sr-only">Sair</span>
      </a>
      <img :src="$store.state.user.email === 'rececao.smf@balizaslandia.pt' ? $store.state.logoBalizaslandia1 : $store.state.logoBalizaslandia2" style="width:68px;" />
      <span style="color:#fff;font-size:20px;">{{relogio}} - {{parseInt(day)}} de {{$t('meses.' + parseInt(month))}} de {{year}}</span>

      <button @click="trocarDia(7)" class="float-right btn btn-warning ml-10">{{diaFN(7)}}</button>
      <button @click="trocarDia(6)" class="float-right btn btn-warning ml-10">{{diaFN(6)}}</button>
      <button @click="trocarDia(5)" class="float-right btn btn-warning ml-10">{{diaFN(5)}}</button>
      <button @click="trocarDia(4)" class="float-right btn btn-warning ml-10">{{diaFN(4)}}</button>
      <button @click="trocarDia(3)" class="float-right btn btn-warning ml-10">{{diaFN(3)}}</button>
      <button @click="trocarDia(2)" class="float-right btn btn-warning ml-10">{{diaFN(2)}}</button>
      <button @click="trocarDia(1)" class="float-right btn btn-warning ml-10">Amanhã</button>
      <button @click="trocarDia(0)" class="float-right btn btn-warning ml-10">Hoje</button>

      <span style="font-size: 21px; color:#fff; margin-top: 4px;" class="float-right mr-15" v-text="totalCriancasFN()"></span>

      <!--<button v-if="!ocultas" @click="ocultas = 1" class="btn btn-success m-10 float-right">Ver Eventos Concluídos</button>
      <button v-else @click="ocultas = 0" class="btn btn-success m-10 float-right">Voltar</button>-->
    </div>
    <div class="cinzento1" style="width:100%">
      <div class="table-responsive cinzento1">
        <table id="tabelaFixa" class="table balizas rececao float-left" style="width: 74.5%;">
          <thead class="hidden-sm-down">
            <tr>
              <th style="top: 0px;width:80px;" class="meio azul text-center border-amarelo">Disp.</th>
              <th style="top: 0px;width:60px;" class="meio  azul text-center">Hora Entrada</th>
              <th style="top: 0px;width:150px;" class="meio azul text-center">Aniversariante</th>
              <th style="top: 0px;width:47px;" class="meio azul text-center">Idade</th>
              <th style="top: 0px;width:60px;" class="meio azul text-center border-amarelo">Cor</th>
              <th style="top: 0px;width:60px;" class="meio azul text-center border-amarelo">Hora Lanche</th>
              <th style="top: 0px;width:60px;" class="meio  azul text-center">Hora Saída</th>
              <th style="top: 0px;width:150px;" class="meio azul text-center">Menu</th>
              <th style="top: 0px;width:300px;" class="meio azul text-center">Extras</th>
              <th style="top: 0px;width:300px;" class="meio azul text-center">Extras Lanche</th>
              <th style="top:0px;" class="meio azul text-center">Observações</th>
              <th style="top: 0px;width:47px;" class="meio azul text-center">Nº C Conf.</th>
              <th style="top: 0px;width:47px;" class="meio azul text-center">Nº C Total</th>
            </tr>
          </thead>
          <tbody style="color:#000;">
            <!--v-show="showFN(dt, index)"-->
            <tr v-for="(dt, index) in res" :key="index" :class="'mao ' + (meta(dt, 'Exterior') ? 'exterior' : '') + (meta(dt, 'saiu') ? ' cinzento1' : '') + (dt.event_at.split(' ')[1] == '14:00:00' && (res[index - 1] || {event_at: ''}).event_at.split(' ')[1] != '14:00:00' || dt.event_at.split(' ')[1] == '17:00:00' && (index == 0 ? 1 : ((res[index - 1] || {event_at: ''}).event_at.split(' ')[1] != '17:00:00')) || dt.event_at.split(' ')[1] == '11:30:00' && (res[index - 1] || {event_at: ''}).event_at.split(' ')[1] != '11:30:00' ? ' border-verm ' : '') + (res[index - 1] ? (meta(res[index - 1], 'saiu') && res[index - 1].event_at == dt.event_at ? ' cinzento1' : '') : '') + (res[index - 2] ? (meta(res[index - 2], 'saiu') && res[index - 2].event_at == dt.event_at ? ' cinzento1' : '') : '')" @click="upData(dt)">
              <!--saiuFN(saidaFN(meta(dt, 'Prolongamento festa (+1h)') ? 3 : 2, dt.event_at, dt))-->
              <td :rowspan="linhasFN(dt, index)" v-if="!dt.ocultar" class="meio text-center" style="color:#777;">
                <span class="float-left">{{meta(dt, 'Nº Dispositivo') == 'Nº Dispositivo' ? 1 : meta(dt, 'Nº Dispositivo') || (dt.tipo === 'cal' ? '' : dispositivoFN(dt.event_at.split(' ')[1]))}}</span>
                <div class="float-right" :class="corFN(meta(dt, 'Lanche'))"></div>
              </td>
              <td :rowspan="linhasFN(dt, index)" v-if="!dt.ocultar" class="azul1 meio hora cinzento1 text-center" style="font-weight:bold;font-size:15px;">{{dt.event_at.split(' ')[1].substring(0, 5)}}</td>
              <td class="meio" >
                <label class="break" v-if="meta(dt, 'menu').indexOf('Centro Estudos') == 0">{{dt.eventable.name}}</label>
                <label class="break" v-html="meta(dt, 'Filho 1')" v-if="meta(dt, 'Filho 1') && meta(dt, 'check1')"></label>
                <label class="break" v-html="meta(dt, 'Filho 2')" v-if="meta(dt, 'Filho 2') && meta(dt, 'check2')"></label>
                <label class="break" v-html="meta(dt, 'Filho 3')" v-if="meta(dt, 'Filho 3') && meta(dt, 'check3')"></label>
                <label class="break" v-html="meta(dt, 'Filho 4')" v-if="meta(dt, 'Filho 4') && meta(dt, 'check4')"></label>
              </td>
              <td class="text-center meio">
                <label class="break" v-html="meta(dt, 'Anos')" v-if="meta(dt, 'Anos') && meta(dt, 'check1')"></label>
                <label class="break" v-html="meta(dt, 'Anos 2')" v-if="meta(dt, 'Anos 2') && meta(dt, 'check2')"></label>
                <label class="break" v-html="meta(dt, 'Anos 3')" v-if="meta(dt, 'Anos 3') && meta(dt, 'check3')"></label>
                <label class="break" v-html="meta(dt, 'Anos 4')" v-if="meta(dt, 'Anos 4') && meta(dt, 'check4')"></label>
              </td>
              <td :rowspan="linhasFN(dt, index)" v-if="!dt.ocultar" @click="dt.id ? upData(dt): $router.push('/balizaslandiacriar' + (dt.id ? '/' + dt.id : '?d=' + dt.event_at))" style="color:#ddd" :class="'text-center hidden-sm-down ' + (dt.tipo === 'cal' ? '' : (meta(dt, 'Cor Pulseira') || coresFN(dt.event_at.split(' ')[1])))"></td>
              <td :rowspan="linhasFN(dt, index)" v-if="!dt.ocultar" class="meio text-left"><span v-if="meta(dt, 'menu').indexOf('Centro Estudos') === -1">{{meta(dt, 'Hora do Lanche') || (dt.tipo === 'cal' ? '' : lancheFN(dt.event_at.split(' ')[1]))}}</span></td>
              <td :rowspan="linhasFN(dt, index)" v-if="!dt.ocultar" class="meio hora text-danger text-center" style="font-weight:bold;font-size:15px;">{{saidaFN(meta(dt, 'Prolongamento festa (+1h)') && meta(dt, 'Prolongamento festa +30m') ? 3.5 : (meta(dt, 'Prolongamento festa (+1h)') ? 3 : (meta(dt, 'Prolongamento festa +30m') ? 2.5 : 2)), dt.event_at, dt)}}</td>
              <td :rowspan="linhasFN(dt, index)" v-if="!dt.ocultar" class="meio">{{meta(dt, 'menu')}}</td>
              <td :rowspan="linhasFN(dt, index)" v-if="!dt.ocultar" class="extras-null meio">
                <label class="break" v-html="meta(dt, 'Pinhata', 'pinhata_tema1')" v-if="meta(dt, 'Pinhata', 'pinhata_tema1')"></label>

                <label class="break" v-html="meta(dt, 'Fotografia')" v-if="meta(dt, 'Fotografia')"></label>
                <label class="break" v-html="meta(dt, 'Convites personalizados', 'Convites_pers_tema')" v-if="meta(dt, 'Convites personalizados')"></label>
                <label class="break" v-html="meta(dt, 'Modelagem de balões')" v-if="meta(dt, 'Modelagem de balões')"></label>
                <label class="break" v-html="meta(dt, 'Pinturas Faciais')" v-if="meta(dt, 'Pinturas Faciais')"></label>
                <label class="break" v-html="meta(dt, 'Espetáculo magia')" v-if="meta(dt, 'Espetáculo magia')"></label>
                <label class="break" v-html="meta(dt, 'Brindes', 'Brindes1')" v-if="meta(dt, 'Brindes', 'Brindes1')"></label>
                <label class="break" v-if="meta(dt, 'Prolongamento festa (+1h)')">Prolongamento festa (+1h)</label>
                <label class="break" v-if="meta(dt, 'Prolongamento festa +30m')">Prolongamento festa (+30m)</label>
                <label class="break" v-html="meta(dt, 'Espaço pais-Proposta 1')" v-if="meta(dt, 'Espaço pais-Proposta 1')"></label>
                <label class="break" v-html="meta(dt, 'Espaço pais-Proposta 2')" v-if="meta(dt, 'Espaço pais-Proposta 2')"></label>
                <label class="break" v-html="meta(dt, 'Espaço pais-Proposta 3')" v-if="meta(dt, 'Espaço pais-Proposta 3')"></label>
                <label class="break" v-html="meta(dt, 'Espaço pais Hora Extra')" v-if="meta(dt, 'Espaço pais Hora Extra')"></label>
                <label class="break" v-html="meta(dt, 'Outros Extras')" v-if="meta(dt, 'Outros Extras')"></label>
              </td>
              <td :rowspan="linhasFN(dt, index)" v-if="!dt.ocultar" class="extras-null meio">
                <label class="break" v-html="boloTemaFN(dt)" v-if="meta(dt, 'Bolo')"></label>
                <label class="break" v-html="meta(dt, 'Hóstia bolo', 'hostia_tema')" v-if="meta(dt, 'Hóstia bolo', 'hostia_tema')"></label>
                <label class="break" v-html="meta(dt, 'Bolo artístico', 'bolo_tema')" v-if="meta(dt, 'Bolo artístico', 'bolo_tema')"></label>
                <label class="break" v-html="meta(dt, 'Festa temática', 'festa_tema')" v-if="meta(dt, 'Festa temática', 'festa_tema')"></label>
                <label class="break" v-html="meta(dt, 'Pipocas')" v-if="meta(dt, 'Pipocas')"></label>
                <label class="break" v-html="meta(dt, 'Crossaints')" v-if="meta(dt, 'Crossaints')"></label>
                <label class="break" v-html="meta(dt, 'Pizzas')" v-if="meta(dt, 'Pizzas')"></label>
                <label class="break" v-html="meta(dt, 'Donuts')" v-if="meta(dt, 'Donuts')"></label>
                <label class="break" v-html="meta(dt, 'Nuggets')" v-if="meta(dt, 'Nuggets')"></label>
                <label class="break" v-html="meta(dt, 'Iorgurtes')" v-if="meta(dt, 'Iorgurtes')"></label>
                <label class="break" v-html="meta(dt, 'Fruta da época')" v-if="meta(dt, 'Fruta da época')"></label>
                <label class="break" v-html="meta(dt, 'Mini muffins')" v-if="meta(dt, 'Mini muffins')"></label>
              </td>
              <td :rowspan="linhasFN(dt, index)" v-if="!dt.ocultar" class="meio text-left">{{dt.observation}} / {{meta(dt, 'Observações Lanche')}} / {{meta(dt, 'Observações Cacifo')}}</td>
              <td :rowspan="linhasFN(dt, index)" v-if="!dt.ocultar" class="meio text-center">{{meta(dt, 'Nº Crianças Confirmadas')}}</td>
              <td :rowspan="linhasFN(dt, index)" v-if="!dt.ocultar" class="meio text-center azul1">{{meta(dt, 'Total Crianças')}}</td>
            </tr>
          </tbody>
        </table>
        <table class="table balizas float-right" style="width: 25%;">
          <thead>
            <tr>
              <th class="meio azul text-center">Horário</th>
              <th class="meio azul text-center">Nome</th>
              <th class="meio azul text-center">OBS</th>
            </tr>
          </thead>
          <tbody style="color:#000;">
            <tr v-for="(dt, index) in res1" :key="index" :class="{'mao': 1 , 'cinzento1': meta(dt, 'saiu'), 'Vermelho': meta(dt, 'validado'), 'blinking' : meta(dt, 'chamar')}" @click="upData(dt, 1, index)" v-show="!meta(dt, 'saiu')">
              <td :class="{'text-center meio': 1, 'text-danger': new Date() >= new Date(dt.event_at.split(' ')[0] + ' ' + dt.saida)}">{{dt.saida}}</td>
              <td class="text-center meio">{{meta(dt, 'Crianças')}}</td>
              <td class="text-center meio"><a v-if="meta(dt, 'Observações Cacifo') || meta(dt, 'Observações Lanche') || dt.observation" class="text-danger" style="font-weight:bold;">Ver</a></td>
            </tr>
          </tbody>
        </table>
      </div>
      <iframe id="talaoPrint" name="talaoPrint" style="visibility:hidden;position:absolute;top:-10000px;width:1px;height:1px;"></iframe>
    </div>
  </div>
</template>

<script>
import { searchEvent, patchEventClient } from '@/resources/fn'
import { mapMutations } from 'vuex'
import { commonFunctionsMixin } from '../filters/funcoes'
import _ from 'lodash'

export default {
  name: 'pageRececao',
  mixins: [commonFunctionsMixin],
  data () {
    return {
      res: [],
      res1: [],
      year: '',
      month: '',
      day: '',
      ano: new Date().getFullYear() + 1,
      dataMin: '2017-01-01',
      eventoInfo: {},
      form: {
        nc: '',
        nt: '',
        nd: '',
        cor: '',
        hora_lanche: '',
        observacoes: '',
        lesoes: '',
        chamar: 0
      },
      scrollable: false,
      amanha: 0,
      relogio: new Date(),
      delayId: null
      // ocultas: 0
    }
  },
  mounted () {
    this.SET_LOAD(true)
    let a = new Date()
    this.month = ('0' + (1 + a.getMonth())).slice(-2)
    this.year = a.getFullYear()
    this.day = ('0' + a.getDate()).slice(-2)
    this.init()
    this.updateTime()
  },
  methods: {
    ...mapMutations([
      'SET_LOAD',
      'SET_TOAST',
      'SET_TITLE'
    ]),
    updateTime () {
      const now = new Date()
      const hours = now.getHours().toString().padStart(2, '0')
      const minutes = now.getMinutes().toString().padStart(2, '0')
      // const seconds = now.getSeconds().toString().padStart(2, '0')
      this.relogio = `${hours}:${minutes}`
      window.setInterval(this.updateTime, 10000)
    },
    totalCriancasFN () {
      return this.res.filter(a => !this.meta(a, 'saiu') && this.meta(a, 'Total Crianças') > 0).reduce((a, b) => a + parseInt(this.meta(b, 'Total Crianças')), 0) + this.res1.filter(a => !this.meta(a, 'saiu')).reduce((a, b) => a + this.meta(b, 'Crianças').split(',').length, 0)
    },
    saidaFN (horas, dta, dt, quinze) {
      let dd = new Date(dta)
      if (horas === 2.5 || horas === 3.5) {
        dd.setMinutes(dd.getMinutes() + 30)
      }
      dd.setHours(dd.getHours() + parseInt(horas))
      if (this.meta(dt, 'menu').indexOf('Centro Estudos') === -1 && !quinze) {
        dd.setMinutes(dd.getMinutes() + 15)
      }
      return dd.getHours() + ':' + ('0' + dd.getMinutes()).slice(-2)
    },
    saiuFN (horas) {
      let a = horas.split(':')
      let dd = new Date()
      if (dd.getHours() >= a[0] && dd.getMinutes() > a[1]) {
        return ' cinzento1'
      }
      return ''
    },
    init () {
      this.formUrl('', 1)
    },
    trocarDia (a) {
      this.amanha = a
      var dd = new Date()
      dd.setDate(dd.getDate() + a)
      this.year = dd.getFullYear()
      this.month = ('0' + (1 + dd.getMonth())).slice(-2)
      this.day = ('0' + dd.getDate()).slice(-2)
      this.formUrl('', 1)
    },
    diaFN (a) {
      var dd = new Date()
      dd.setDate(dd.getDate() + a)
      return dd.getDate() + ' ' + this.$t('meses.' + (1 + dd.getMonth())).substring(0, 3)
    },
    addHours(date) {
      let dd = new Date(date)
      dd.setHours(dd.getHours() + 4.5)
      return dd
    },
    formUrl () {
      this.SET_LOAD(true)
      let cal = []
      switch (this.$store.state.user.email) {
        case 'rececao.smf@balizaslandia.pt':
          cal = [6, 67]
          break
        case 'rececao.oaz@balizaslandia.pt':
          cal = [107, 110]
          break
      }
      searchEvent(this.axios, cal, this.year, this.month, '', '', '', this.day, '').then((res) => {
        this.res = res.data.filter(a => !this.meta(a, 'Bloqueado') && a.meta[0] && new Date() < this.addHours(a.event_at) && parseInt(a.calendar_id) === cal[0])
        let x = res.data.filter(a => !this.meta(a, 'Bloqueado') && parseInt(a.calendar_id) === cal[1]).map(a => {
          a.saida = this.saidaFN(this.meta(a, 'tempo') == 3 ? 3 : (this.meta(a, 'tempo') == 2 ? 2 : 1), a.event_at, a, 1)
          return a
        })
        this.res1 = x.sort((a, b) => a.saida.localeCompare(b.saida))
        this.SET_LOAD()
        this.delayId = _.delay(function (a) {
          a.formUrl()
        }, 60000, this)
      }, (er) => {
        this.SET_TOAST({ msg: er.data, type: 'danger' })
      })
    },
    upData (dt, livre) {
      console.log(dt, livre)
      if (!dt.id) {
        return
      }
      clearTimeout(this.delayId)
      let a = this.meta(dt, 'Nº Dispositivo')
      this.$modal.show('opcoesEventos')
      this.eventoInfo = dt
      this.form = {
        nd: a === 'Nº Dispositivo' ? 1 : a || this.dispositivoFN(),
        cor: this.meta(dt, 'Cor Pulseira') || this.coresFN(),
        hora_lanche: this.meta(dt, 'Hora do Lanche') || this.lancheFN(),
        observacoes: dt.observation,
        fim: this.meta(dt, 'saiu'),
        criancas: this.meta(dt, 'Crianças').replace(/;;/g, '').replace(/;/g, ', '),
        total: this.meta(dt, 'Total Crianças'),
        // lesoes: this.meta(dt, 'Observações Lesões'),
        cacifos: this.meta(dt, 'Observações Cacifo'),
        lanche: this.meta(dt, 'Observações Lanche'),
        livre: livre,
        chamar: this.meta(dt, 'chamar')
      }
    },
    atualizar () {
      this.SET_LOAD(true)
      patchEventClient(this.axios, {
        id: this.eventoInfo.id,
        calendar_id: this.eventoInfo.calendar_id,
        color: this.eventoInfo.color,
        event_at: this.eventoInfo.event_at.substring(0, 16),
        observation: this.form.observacoes,
        meta: {
          'Nº Dispositivo': this.form.nd,
          'Cor Pulseira': this.form.cor,
          'Hora do Lanche': this.form.hora_lanche,
          'saiu': this.form.fim,
          'Observações Lanche': this.form.lanche,
          'Observações Cacifo': this.form.cacifos,
          'chamar': this.form.chamar
          // 'Observações Lesões': this.form.lesoes
        }
      }).then(() => {
        this.SET_TOAST({ msg: 'Atualizado com sucesso.' })
        this.SET_LOAD()
        this.$modal.hide('opcoesEventos')
        this.formUrl('', 1)
      }, (er) => {
        this.SET_TOAST({ msg: this.$t(er.response.data.error), type: 'error' })
      })
    },
    /*
    showFN (dt, index) {
      if (index) {
        let i = index
        while (this.res[--i]) {
          if (dt.event_at === this.res[i].event_at) {
            if (this.res[i - 1]) {
              if (dt.event_at === this.res[i - 1].event_at) {
                return this.meta(this.res[i - 1], 'saiu') && this.ocultas || !this.meta(this.res[i - 1], 'saiu') && !this.ocultas
              }
            }
            return this.meta(this.res[i], 'saiu') && this.ocultas || !this.meta(this.res[i], 'saiu') && !this.ocultas
          }
        }
      }
      return this.meta(dt, 'saiu') && this.ocultas || !this.meta(dt, 'saiu') && !this.ocultas
    }
    /*
    imprimirFN () {
      let doc = document.getElementById('talaoPrint').contentWindow.document
        doc.open()
        doc.write('<html><head></head><body>' + this.form.criancas.replace(/,/, '<br />') + '<br />Total de Crianças: ' + this.form.total + '</body></html>')
        doc.close()
        _.delay(function () {
          document.getElementById('talaoPrint').contentWindow.focus()
          document.getElementById('talaoPrint').contentWindow.print()
        }, 500, this)
    }
    */
  }
}
</script>
